import useAccount from "@/hooks/useAccount";
import { useNetwork, useEnsName } from "wagmi";
import useSWR from "swr";

const fetcher = (url: string) => fetch(url).then((r) => r.json());

export default function useNameService() {
  const { address, isLogin } = useAccount();
  const { chain } = useNetwork();

  const { data: ensName } = useEnsName({
    address,
    enabled: isLogin && chain?.id === 1,
  });

  const { data: bnbRes } = useSWR(
    isLogin && chain?.id === 56
      ? `${window.location.origin}/api/spaceid?address=${address}&tld=bnb`
      : null,
    fetcher
  );

  const { data: arbRes } = useSWR(
    isLogin && chain?.id === 42161
      ? `${window.location.origin}/api/spaceid?address=${address}&tld=arb1`
      : null,
    fetcher
  );

  const { data: zkName } = useSWR(
    isLogin && chain?.id === 324
      ? `https://omniapi.zkns.app/domain-resolver/getReverseRecord/${address}`
      : null,
    (url: string) =>
      fetch(url).then((r) => {
        return r.text();
      })
  );

  const name = ensName || bnbRes?.name || arbRes?.name || zkName;

  return {
    name,
    hasName: !!name,
  };
}
