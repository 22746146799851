import {
  CarrotIcon,
  AnalyticsIcon,
  FormIcon,
  GiftIcon,
  ConnectIcon,
  NetworkIcon,
  DashboardVCIcon,
  StakingTabIcon,
} from "@/components/common/icons";

export const APP_ROUTES = {
  dashboard: {
    Icon: CarrotIcon,
    label: "Home",
    route: "/dashboard",
  },
  analytics: {
    Icon: AnalyticsIcon,
    label: "Analytics",
    route: "/dashboard/analytics",
  },
  analytics_create: {
    route: "/dashboard/analytics/create",
  },
  form: {
    Icon: FormIcon,
    label: "Form",
    route: "/dashboard/forms",
  },
  form_start_create: {
    route: "/dashboard/forms/start_create",
  },
  form_create: {
    route: "/dashboard/forms/new",
  },
  form_edit: {
    route: "/dashboard/forms/[id]",
  },
  form_response: {
    route: "/dashboard/forms/[id]/response",
  },
  form_share: {
    route: "/dashboard/forms/[id]/share",
  },
  airdrop: {
    Icon: GiftIcon,
    label: "Airdrop",
    route: "/dashboard/airdrops",
  },
  airdrop_create: {
    route: "/dashboard/airdrops/create",
  },
  airdrop_start_create: {
    route: "/dashboard/airdrops/start_create",
  },
  connection: {
    Icon: ConnectIcon,
    label: "Connection",
    route: "/dashboard/connections",
  },
  network: {
    Icon: NetworkIcon,
    label: "Network",
    route: "/dashboard/network",
  },
  vc: {
    Icon: DashboardVCIcon,
    label: "Verifiable Credential",
    route: "/dashboard/credential",
  },
  staking: {
    Icon: StakingTabIcon,
    label: "Staking",
    route: "/dashboard/staking",
  },
  katRewards: {
    Icon: StakingTabIcon,
    label: `KAT Reward`,
    route: "/dashboard/kat-rewards",
  },
};

type RouteType = keyof typeof APP_ROUTES;

export function getRoute(id: RouteType) {
  const route = APP_ROUTES[id];
  return {
    ...route,
    id,
  };
}
