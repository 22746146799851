import { FunctionComponent } from "react";
import { WalletIcon } from "@/components/common/icons";
import Link from "next/link";
import { Divider, Menu } from "@mantine/core";
import { useRouter } from "next/router";
import { getRoute } from "@/services/config";
import useAccount from "@/hooks/useAccount";
import { trimAddr } from "@/services/format";
import { useEnsName } from "wagmi";

import {
  mainnet,
  bsc,
  polygon,
  avalanche,
  goerli,
  bscTestnet,
  avalancheFuji,
  polygonMumbai,
  zkSync,
  zkSyncTestnet,
  arbitrum,
} from "wagmi/chains";
import { useNetwork, useSwitchNetwork } from "wagmi";
import { KCCChain, KCCChain_Test } from "@/chain-info/KCCChain";
import { ZetaChain_Test } from "@/chain-info/ZetaChain";
import { getChainIcon } from "@/utils/format";

export const tabs = [
  getRoute("analytics"),
  getRoute("form"),
  getRoute("airdrop"),
  getRoute("connection"),
  getRoute("network"),
  getRoute("vc"),
];

if (process.env.NEXT_PUBLIC_STAKING_PAGE_VISIBLE === "true") {
  tabs.push(getRoute("staking"));
  tabs.push(getRoute("katRewards"));
}

export const menus = [
  {
    name: "Ethereum",
    icon: getChainIcon("Ethereum"),
    chain: mainnet,
  },
  {
    name: "BNB",
    icon: getChainIcon("BSC"),
    chain: bsc,
  },
  {
    name: "Polygon",
    icon: getChainIcon("Polygon"),
    chain: polygon,
  },
  {
    name: "Avalanche",
    icon: getChainIcon("Avalanche"),
    chain: avalanche,
  },
  {
    name: "zkSync",
    icon: getChainIcon("zkSync"),
    chain: zkSync,
  },
  {
    name: "KCC",
    icon: getChainIcon("KCC"),
    chain: {
      id: KCCChain.chainId,
      name: KCCChain.chainName,
      network: "kcc",
      nativeCurrency: KCCChain.nativeCurrency,
      rpcUrls: {
        default: { http: [KCCChain.rpcUrl] },
      },
    },
  },
  {
    name: "Arbitrum",
    chain: arbitrum,
    icon: getChainIcon("Arbitrum"),
  },
  {
    name: "ZetaTestnet",
    icon: getChainIcon("ZetaTestnet"),
    chain: {
      id: ZetaChain_Test.chainId,
      name: ZetaChain_Test.chainName,
      network: "zeta-testnet",
      nativeCurrency: ZetaChain_Test.nativeCurrency,
      rpcUrls: {
        default: { http: [ZetaChain_Test.rpcUrl] },
      },
    },
  },
];

export let testNetworkMenus = [
  {
    name: "Goerli",
    icon: getChainIcon("Goerli"),
    chain: goerli,
  },
];

if (process.env.NODE_ENV !== "production") {
  testNetworkMenus = (testNetworkMenus as any).concat([
    {
      name: "BSCTestnet",
      icon: getChainIcon("BSCTestnet"),
      chain: bscTestnet,
    },
    {
      name: "AvalancheTestnet",
      icon: getChainIcon("AvalancheTestnet"),
      chain: avalancheFuji,
    },
    {
      name: "zkSync Testnet",
      icon: getChainIcon("zkSync Testnet"),
      chain: zkSyncTestnet,
    },
    {
      name: "KCC Testnet",
      icon: getChainIcon("KCCTest"),
      chain: {
        id: KCCChain_Test.chainId,
        name: KCCChain_Test.chainName,
        network: "kkc-testnet",
        nativeCurrency: KCCChain_Test.nativeCurrency,
        rpcUrls: {
          default: { http: [KCCChain_Test.rpcUrl] },
        },
      },
    },
    {
      name: "Polygon Mumbai",
      icon: getChainIcon("Polygon"),
      chain: polygonMumbai,
    },
  ]);
}

const AppMenu: FunctionComponent = () => {
  const router = useRouter();
  const { login, logout, isLogin, address } = useAccount();
  const { chain } = useNetwork();
  const { chains, switchNetwork } = useSwitchNetwork();
  const {
    data: ensName,
    isError,
    isLoading,
  } = useEnsName({
    address,
  });

  return (
    <div className="w-[160px] h-full rounded-lg flex-shrink-0 bg-dark1 px-4 py-6 flex flex-col">
      {[getRoute("dashboard")].map((tab: any) => {
        const isActive = router.pathname === tab.route;
        return (
          <Link href={`${tab.route}`} key={tab.id}>
            <div className="flex py-3 pb-0 action">
              <tab.Icon
                className={`flex-shrink-0 ${
                  isActive ? "fill-[#87B5FF]" : "fill-[#8F8F8F]"
                }`}
              ></tab.Icon>
              <span
                className={`ml-2 text-sm font-semibold ${
                  isActive ? "text-white" : "text-[#8F8F8F]"
                }`}
              >
                {tab.label}
              </span>
            </div>
          </Link>
        );
      })}
      <Divider color={"#3A3A3A"} className="mt-7"></Divider>
      <div className="py-4">
        {tabs.map((tab: any) => {
          const isActive = router.pathname.indexOf(tab.route) === 0;
          return (
            <Link href={`${tab.route}`} key={tab.id}>
              <div className="flex items-center py-3 action last:pb-0">
                <tab.Icon
                  className={`flex-shrink-0 ${
                    isActive ? "fill-[#87B5FF]" : "fill-[#8F8F8F]"
                  }`}
                ></tab.Icon>
                <span
                  className={`ml-2 text-sm font-semibold whitespace-pre-line ${
                    isActive ? "text-white" : "text-[#8F8F8F]"
                  }`}
                >
                  {tab.label}
                </span>
              </div>
            </Link>
          );
        })}
      </div>
      <div className="flex-1"></div>
    </div>
  );
};

export default AppMenu;
