import { FunctionComponent, useState, useEffect } from "react";
import useAccount from "@/hooks/useAccount";
import { Button, Menu, Tooltip } from "@mantine/core";
import { useNetwork, useSwitchNetwork } from "wagmi";
import { menus, testNetworkMenus } from "@/components/common/AppMenu";
import { trimAddr, katAmountDesc } from "@/utils/format";
import { addKATToMetamask } from "@/services/web3";
import { useRouter } from "next/router";
import useNameService from "@/hooks/chain/useNameService";

const fullMenus = [].concat(menus as any, testNetworkMenus as any);

const ConnectWallet: FunctionComponent = () => {
  const { login, logout, isLogin, address, balance } = useAccount();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const router = useRouter();
  const [activeMenu, setActiveMenu] = useState<any>(null);
  const { hasName, name } = useNameService();

  useEffect(() => {
    const active = fullMenus.find((item: any) => {
      return item.chain.id === chain?.id;
    });
    setActiveMenu(active);
  }, [chain]);

  return (
    <>
      {isLogin && (
        <Menu shadow="md" position="bottom-end" withinPortal>
          <Menu.Target>
            <div className="flex items-center bg-[#F9F9F9] rounded-3xl">
              <div className="text-sm pl-6 mr-4 hidden sm:flex  items-center">
                <img src="/kat-icon.png" className="w-5 h-5 mr-1"></img>{" "}
                {katAmountDesc(balance.toNumber(), 2)} KAT
              </div>
              <Button
                className={`px-6 text-sm bg-white overflow-hidden`}
                variant="outline"
                radius={"xl"}
              >
                {activeMenu && (
                  <img
                    src={activeMenu.icon}
                    alt={activeMenu.name}
                    className="w-4 h-4 mr-2"
                  />
                )}
                {hasName ? (
                  <span className="max-w-[120px]  overflow-hidden text-ellipsis whitespace-nowrap">
                    {name}
                  </span>
                ) : (
                  trimAddr(address as string, 3)
                )}
              </Button>
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            {hasName && (
              <>
                <Menu.Item>{trimAddr(address as string, 8)}</Menu.Item>
                <Menu.Divider />
              </>
            )}
            <Menu.Label>Select a Network</Menu.Label>
            {menus.map((menu) => {
              return (
                <Menu.Item
                  key={menu.name}
                  icon={
                    <img src={menu.icon} alt={menu.name} className="w-4 h-4" />
                  }
                  onClick={() => {
                    // @ts-ignore
                    switchNetwork(menu.chain.id);
                  }}
                >
                  <p className="font-bold">
                    {menu.name}
                    {chain?.id === menu.chain.id ? "(Active)" : null}
                  </p>
                </Menu.Item>
              );
            })}
            <Menu.Label>Test Network</Menu.Label>
            {testNetworkMenus.map((menu) => {
              return (
                <Menu.Item
                  key={menu.name}
                  icon={
                    <img src={menu.icon} alt={menu.name} className="w-4 h-4" />
                  }
                  onClick={() => {
                    // @ts-ignore
                    switchNetwork(menu.chain.id);
                  }}
                >
                  <p className="font-bold">
                    {menu.name}
                    {chain?.id === menu.chain.id ? "(Active)" : null}
                  </p>
                </Menu.Item>
              );
            })}
            <Menu.Divider />
            <div className="block sm:hidden">
              <Menu.Item>
                <div className="flex justify-between items-center">
                  <div>Balance</div>
                  <div className="text-xs">
                    {katAmountDesc(Number(balance), 2)} KAT
                  </div>
                </div>
              </Menu.Item>
            </div>
            <Menu.Item onClick={addKATToMetamask}>
              Add KAT to Metamask
            </Menu.Item>
            <Menu.Item onClick={() => router.push("/settings")}>
              Settings
            </Menu.Item>
            <Menu.Item
              onClick={() => navigator.clipboard.writeText(address || "")}
            >
              Copy address
            </Menu.Item>
            <Menu.Item onClick={logout}>Disconnect wallet</Menu.Item>
          </Menu.Dropdown>
        </Menu>
      )}
      {!isLogin && (
        <Button className={`px-6 ml-4`} onClick={login} radius="xl">
          Connect wallet
        </Button>
      )}
    </>
  );
};

export default ConnectWallet;
