import { GoogleCalendar } from "datebook";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import _normalizeUrl from "normalize-url";
import { KccIcon } from "@/chain-info/KCCChain";
import { ZetaIcon } from "@/chain-info/ZetaChain";
import BigNumber from "bignumber.js";

dayjs.extend(duration);

export const trimAddr = (addr: string, n = 3) =>
  addr?.substring(0, n + 2) + "..." + addr?.slice(-n);

export const trimAddr1 = (addr: string, n = 3) =>
  addr?.substring(0, n) + "..." + addr?.slice(-n);

export const trimAddr2 = (addr: string) =>
  addr?.substring(0, 18) + "..." + addr?.slice(-14);

export function twitterUserLink(account: string) {
  return `https://twitter.com/${account}`;
}

export function discordUserLink(uid: string) {
  return `https://discordapp.com/users/${uid}`;
}

export function googleMapLink(location: string) {
  return `https://www.google.com/maps/place/${location.replaceAll(" ", "+")}`;
}

export function telegramUserLink(user: string) {
  return "";
}

export function emailLink(email: string) {
  return `mailto:${email}`;
}

export function etherscanAddressLink(address: string) {
  return `https://etherscan.io/address/${address}`;
}

export function googleCalendarLink(config: {
  title: string;
  description: string;
  start: Date;
  end: Date;
  location?: string;
}) {
  return new GoogleCalendar(config as any).render();
}

export function dateShortDesc(date: Date) {
  const day = dayjs(date);
  if (day.isSame(dayjs(), "year")) {
    return day.format("MM/DD");
  } else {
    return day.format("MM/DD/YYYY");
  }
}

export function relativeDateDesc(date: Date) {
  const duration = dayjs.duration(dayjs().diff(dayjs(date)));
  const years = duration.asYears();
  const months = duration.asMonths();
  const days = duration.asDays();
  if (years > 1) {
    return `${Math.floor(years)} years ago`;
  } else if (months > 1) {
    return `${Math.floor(months)} month ago`;
  } else if (days > 60) {
    return ">60 days ago";
  } else if (days < 1) {
    return `${Math.floor(duration.hours())} hours ago`;
  } else {
    return `${Math.floor(days)} days ago`;
  }
}

export function walletAge(date: Date) {
  const duration = dayjs.duration(dayjs().diff(dayjs(date)));
  const years = duration.asYears();
  const months = duration.asMonths();
  const days = duration.asDays();
  if (years > 1) {
    return `${Math.floor(years)} year`;
  } else if (months > 1) {
    return `${Math.floor(months)} month`;
  } else if (days > 1) {
    return `${Math.floor(days)} day`;
  } else {
    return "today";
  }
}

export function lastActiveDesc(date: Date) {
  const duration = dayjs.duration(dayjs().diff(dayjs(date)));
  const days = duration.asDays();
  if (days < 1) {
    return "today";
  } else if (days > 60) {
    return "over 60 days ago";
  } else {
    return `${Math.floor(days)} days ago`;
  }
}

export function normalizeUrl(text = "") {
  try {
    const expression =
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    if (text.match(expression)) {
      return _normalizeUrl(text);
    }
    return text;
  } catch (e) {
    return text;
  }
}

export function numberWithCommas(value: number = 0) {
  let v = value.toString();
  const partIndex = v.indexOf(".");
  let part;
  if (partIndex > -1) {
    part = v.slice(partIndex + 1);
    v = v.slice(0, partIndex);
  }
  return v.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (part ? `.${part}` : "");
}

export function svgToDataUrl(svg: string) {
  return `data:image/svg+xml;charset=UTF-8,${svg}`;
}

const ethereum = "/airdrop_assets/network/ethereum.png";
const bsc = "/airdrop_assets/network/bsc.png";
const polygon = "/airdrop_assets/network/polygon-matic-logo.png";
const avalanche = "/airdrop_assets/network/avalanche-avax-logo.png";
const arbitrum = "https://arbitrum.io/favicon.ico";

export function getChainIcon(
  name:
    | "Ethereum"
    | "Polygon"
    | "BSC"
    | "Smart Chain"
    | "Avalanche"
    | "AvalancheTestnet"
    | "Goerli"
    | "BSCTestnet"
    | "Smart Chain Testnet"
    | "KCC"
    | "KCCTest"
    | "zkSync"
    | "zkSync Era"
    | "zkSync Testnet"
    | "Arbitrum"
    | "ZetaTestnet"
) {
  return name === "Ethereum"
    ? ethereum
    : name === "Polygon"
    ? polygon
    : name === "BSC" ||
      name === "BSCTestnet" ||
      name === "Smart Chain" ||
      name === "Smart Chain Testnet"
    ? bsc
    : name === "KCC" || name === "KCCTest"
    ? KccIcon
    : name === "ZetaTestnet"
    ? ZetaIcon
    : name === "Arbitrum"
    ? arbitrum
    : name === "zkSync" || name === "zkSync Testnet" || name === "zkSync Era"
    ? "/assets/zksync-dark-logo.png"
    : name === "Goerli"
    ? ethereum
    : name === "Avalanche" || name === "AvalancheTestnet"
    ? avalanche
    : undefined;
}

export const B = 1e9;
export const M = 1e6;
export const K = 1e3;

export const amountDesc = (amount?: number, decimal?: number) => {
  let rtn = "";
  if (!amount) {
    rtn = (0).toFixed(decimal);
  } else if (amount >= B) {
    rtn = (amount / B).toFixed(decimal) + "B";
  } else if (amount >= M) {
    rtn = (amount / M).toFixed(decimal) + "M";
  } else if (amount >= K) {
    rtn = (amount / K).toFixed(decimal) + "K";
  } else {
    rtn = amount.toFixed(decimal);
  }
  if (!decimal) {
    return rtn;
  }
  return rtn.replace(
    (0).toFixed(decimal || 0).slice(-((decimal || 0) + 1)),
    ""
  );
};

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function KATAmountFormat(amount?: string | number) {
  if (typeof amount === "undefined") {
    return "--";
  }
  if (amount === "-" || amount === "--") {
    return "--";
  }
  let val = amount;
  if (typeof amount === "string") {
    val = parseFloat(amount);
  }
  return numberWithCommas(parseFloat((val as number).toFixed(6)));
}

export function stakedAmountDisplay(amount: BigNumber) {
  if (amount.isGreaterThan(1e10)) {
    return `${numberWithCommas(amount.div(1e10).toNumber())} million`;
  } else if (amount.isGreaterThan(1e4)) {
    return `${numberWithCommas(amount.div(1e3).toNumber())} thousand`;
  } else {
    return numberWithCommas(amount.toNumber());
  }
}

export const katAmountDesc = (amount?: number, decimal?: number) => {
  let rtn = "";
  if (!amount) {
    rtn = (0).toFixed(decimal);
  } else if (amount >= B) {
    rtn = (amount / B).toFixed(decimal) + "B";
  } else if (amount >= M) {
    rtn = (amount / M).toFixed(decimal) + "M";
  } else {
    rtn = numberWithCommas(parseFloat(amount.toFixed(decimal)));
  }
  if (!decimal) {
    return rtn;
  }
  return rtn.replace(
    (0).toFixed(decimal || 0).slice(-((decimal || 0) + 1)),
    ""
  );
};
